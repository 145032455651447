<!-- 问答库  -->
<template>
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="标题：">
          <el-input maxlength="15" placeholder="请输入标题" v-model="queryForm.questions_desc" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="问题：">
          <el-input maxlength="15" placeholder="请输入问题" v-model="queryForm.questions_sample" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
         <el-form-item label="关键词：">
          <el-input maxlength="15" placeholder="请输入关键词" v-model="queryForm.keywords" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="问答库">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('5490175b5d464941826f7117')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="openAddForm()" v-if="isShowBtn('5490175b5d464941826f7116')">新增
            </lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @selection-change="selectionChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="answers">
              <ul>
                <li v-for="(item,index) in row.answers" :key="index" class="answerStyle">{{ item }}</li>
              </ul>
            </template>
            <template slot-scope="{row}" slot="is_active">
              <lebo-switch :status="!row.is_active" :turnOn="''" :turnOff="''" :switchType="row.is_active"
                @switchChange="changeStatus(row)" v-if="isShowBtn('5490175b5d464941826f7109')"></lebo-switch>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handleit(row)"  v-if="isShowBtn('5490175b5d464941826f7108')"></lbButton>
            </template>
          </avue-crud> -->
           <!-- 表格 -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe @selection-change="selectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column  prop="questions_desc" label="标题"></el-table-column>
          <el-table-column prop="questions_sample" label="问题"></el-table-column>
          <el-table-column prop="answers" label="答案" width="500">
            <template slot-scope="scope">
              <ul>
                <li v-for="(item,index) in scope.row.answers" :key="index" class="answerStyle">{{ item }}</li>
              </ul>
              </template>
          </el-table-column>
          <el-table-column prop="keywords" label="关键词"></el-table-column>
          <el-table-column prop="matching_number" label="匹配个数" width="120"></el-table-column>
          <el-table-column prop="is_active" label="状态" width="150">
            <template slot-scope="scope">
              <lebo-switch :status="!scope.row.is_active" :turnOn="''" :turnOff="''" :switchType="scope.row.is_active"
                @switchChange="changeStatus(scope.row)" v-if="isShowBtn('5490175b5d464941826f7109')"></lebo-switch>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handleit(scope.row)"  v-if="isShowBtn('5490175b5d464941826f7108')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="sizeChange" @current-change="currentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增编辑问答库 -->
    <lebo-dialog append-to-body :title="dialogFAQLibraryId ? '编辑问答库' : '新增问答库'" :isShow="showAddFAQLibrary" width="65%"
      @close="showAddFAQLibrary = false" footerSlot>
      <addFAQLibrary @closeDialog="closeFAQLibrary" v-if="showAddFAQLibrary" :id="dialogFAQLibraryId" :active="dialogActive"></addFAQLibrary>
    </lebo-dialog>
  </div>
</template>

<script>
import { getAiQuestionsAnswers, deletAiQuestionsAnswers, UpdateAiQuestionsActive } from '@/api/maintenanceCenter.js'
import addFAQLibrary from './components/addFAQ/index.vue'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'FAQLibrary',
  components: { addFAQLibrary },
  props: {},
  data () {
    return {
      // 查询表单
      queryForm: {
        questions_desc: '', // 标题
        questions_sample: '', // 问题
        keywords: '', // 关键词
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      showAddFAQLibrary: false, // 新增编辑问答库弹框
      dialogFAQLibraryId: '', // 弹框问答id
      dialogActive: false, // 是否启用问题
      // option: {
      //   searchMenuSpan: 8,
      //   header: false,
      //   delBtn: false, // 行删除按钮
      //   editBtn: false, // 编辑按钮
      //   selection: true,
      //   selectionFixed: false, // 解除选择框冻结
      //   menuAlign: 'left', // 属性对齐方式
      //   menuHeaderAlign: 'left', // 表头对齐方式
      //   menu: true,
      //   menuFixed: false, // 解除操作框冻结
      //   tip: false,
      //   column: [
      //     {
      //       label: '标题',
      //       prop: 'questions_desc'
      //     }, {
      //       label: '问题',
      //       prop: 'questions_sample',
      //       width: 300
      //     }, {
      //       label: '答案',
      //       prop: 'answers',
      //       slot: true,
      //       width: 300
      //     }, {
      //       label: '关键词',
      //       prop: 'keywords',
      //       width: 250
      //     }, {
      //       label: '匹配个数',
      //       prop: 'matching_number'
      //     }, {
      //       label: '状态',
      //       prop: 'is_active',
      //       slot: true
      //     }
      //   ]
      // },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAnswerLibrary()
    },
    // 每页条数改变
    sizeChange (val) {
      this.page.currentPage = 1
      this.page.pageSize = val
      this.queryForm.PageSize = val
      this.fnGetAnswerLibrary()
    },
    // 页码改变
    currentChange (val) {
      this.queryForm.PageIndex = val
      this.page.currentPage = val
      this.fnGetAnswerLibrary()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 选中的商家号
    selectionChange (list) {
      this.multipleSelection = list
    },
    // 关闭弹框
    closeFAQLibrary () {
      this.showAddFAQLibrary = false
      this.fnGetAnswerLibrary()
    },
    // 获取问答库
    async fnGetAnswerLibrary () {
      const res = await getAiQuestionsAnswers(this.queryForm)
      console.log('问答库res', res)
      this.tableData = res.Data.DataList
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的问题, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              this.fnDeleteAIquestions(arr)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的问题！')
      }
    },
    // 删除
    async fnDeleteAIquestions (arr) {
      const res = await deletAiQuestionsAnswers(arr)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetAnswerLibrary()
    },
    // 新增
    openAddForm () {
      this.showAddFAQLibrary = true
      this.dialogFAQLibraryId = ''
      this.dialogActive = false
    },
    // 编辑
    handleit (row) {
      this.showAddFAQLibrary = true
      this.dialogFAQLibraryId = row._id
      this.dialogActive = row.is_active
    },
    // 开启状态
    async changeStatus (row, type) {
      console.log('row', row)
      console.log('type', type)
      // this.row = row
      // this.batchChangDialogVisible = true
      const obj = {}
      obj._id = row._id
      obj.is_active = !row.is_active
      console.log('obj', obj)
      await UpdateAiQuestionsActive(obj)
      this.fnGetAnswerLibrary()
    }
    // 更改状态

  },
  created () { },
  mounted () {
    this.fnGetAnswerLibrary()
  }
}
</script>

<style scoped lang="less">
.answerStyle{
  list-style: initial;
  margin-left: 10px;
}
</style>
